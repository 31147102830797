import * as React from 'react'
import css from './ProductDetailsRow.scss'
import { IStoreLocationWithDistance } from '../../../StoreLocator/StoreLocator'
import { faHandHoldingHeart, faInfoCircle, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IProduct } from '../../../Omnimerse/cms/Frontend/omnistudio-frontend-components/src/PDP'
import MessagesIcon from './MessagesIcon'
import GenericMessageModal from '../../../Common/Modal/GenericMessageModal'
import { IMorShippingType } from '../../Context/MorCartContext'

interface IProductAvailabilityProps {
  customerMessage: string
  myClosestStore?: IStoreLocationWithDistance | null
  showLocationSelect?: boolean
  setShowLocationSelect?: (locationSelect: boolean) => void
}

export const ProductAvailability = (props: IProductAvailabilityProps) => {
  const { customerMessage, myClosestStore, showLocationSelect, setShowLocationSelect } = props

  const toggleLocationSelect = () => {
    setShowLocationSelect && setShowLocationSelect(!showLocationSelect)
  }

  return (
    <div className={css.availability}>
      {myClosestStore && customerMessage && <b>{customerMessage}</b>}
      {myClosestStore === null && (
        <b>
          Please <a onClick={toggleLocationSelect}>update your location</a> for accurate delivery information.
        </b>
      )}
    </div>
  )
}

export const renderIncludes = (targetProduct: IProduct) => {
  return (
    <div>
      {targetProduct?.description && <div className={css.summary}>{'INCLUDES ' + targetProduct.description}</div>}
    </div>
  )
}

export const renderDeliveryDetails = () => {
  const [showDeliveryDetailsModal, setShowDeliveryDetailsModal] = React.useState(false)
  const [contentToDisplay, setContentToDisplay] = React.useState<string | null>(null)

  const renderShippingContent = () => {
    return (
      <>
        <h3 className={css.mainHeading}>White Glove Delivery Service</h3>
        <div className={css.deliveryDetailsModalBody}>
          <div className={css.tabTop}>
            <p className={css.textItalic}>Get premium service every step of the way with our White Glove Delivery.</p>
          </div>
          <div className={css.tabDetails}>
            <h4 className={css.subtitle}>Scheduling a Delivery</h4>
            <p className={css.textNormal}>
              An email or text will be sent to confirm your delivery time and let you know when your furniture is on its
              way.
            </p>
            <h4 className={css.subtitle}>Full-Service Assembly</h4>
            <p className={css.textNormal}>
              Furniture will be placed directly in the room of your choice. Furniture will be professionally unpacked,
              assembled and placed exactly where you want it.
            </p>
            <h4 className={css.subtitle}>Inspection</h4>
            <p className={css.textNormal}>
              Thorough inspection of each furniture piece will be completed before departure.
            </p>
            <h4 className={css.subtitle}>Furniture Walk-Through</h4>
            <p className={css.textNormal}>
              A full explanation will be given of all the features and benefits of your items along with tutorials of
              any functions.
            </p>
            <h4 className={css.subtitle}>Clean Up</h4>
            <p className={css.textNormal}>
              Removal of all packaging materials for recycling & disposal. Removal and disposal of your old mattress
              with purchase of a new mattress or foundation.
            </p>
            <h4 className={css.subtitle}>Extended Delivery</h4>
            <p className={css.textNormal}>We offer delivery outside of our local delivery area for a surcharge.</p>
            <h4 className={css.subtitle}>Delivery Requirements</h4>
            <p className={css.textNormal}>A signature from someone 18 years or older will be required.</p>
          </div>
        </div>
      </>
    )
  }

  const renderPickupContent = () => {
    return (
      <>
        <h3 className={css.mainHeading}>Free Warehouse Pickup</h3>
        <div className={css.deliveryDetailsModalBody}>
          <div className={css.tabTop}>
            <p className={css.textItalic}>Customers can pick up orders at our warehouse free of charge.</p>
          </div>
          <div className={css.tabDetails}>
            <h4 className={css.subtitle}>Scheduling a Pickup</h4>
            <p className={css.textNormal}>
              Text customer service at 37197 to schedule your warehouse pickup. Customers will receive a text when the
              order is ready (or email if text isn’t successful/available).
            </p>
            <h4 className={css.subtitle}>Pickup Requirements</h4>
            <p className={css.textNormal}>
              You need to have a vehicle capable of safely transporting your furniture. Please bring ID, sales order,
              vehicle packing material, padding, and rope.
            </p>
            <h4 className={css.subtitle}>Assistance</h4>
            <p className={css.textNormal}>
              Our warehouse personnel will assist you in loading your vehicle, but you are ultimately responsible for
              securing the merchandise to your vehicle.
            </p>
            <h4 className={css.subtitle}>Self-Assembly</h4>
            <p className={css.textNormal}>
              Many items will be in factory packaging therefore some merchandise will require assembly.
            </p>
          </div>
        </div>
      </>
    )
  }

  return (
    <div className={css.deliveryDetailsContainer}>
      <div className={css.deliveryDetailsContainerOptions}>
        <div className={css.deliveryOption}>
          <div className={css.optionIconLeft}>
            <FontAwesomeIcon icon={faHandHoldingHeart} />
          </div>
          <div className={css.optionDetailsRight}>
            <p className={css.optionTextLink}>
              White Glove Delivery
              <button
                onClick={() => {
                  setContentToDisplay(IMorShippingType.SHIPPING)
                  setShowDeliveryDetailsModal(true)
                }}
              >
                Learn More
              </button>
            </p>
          </div>
        </div>
        <div className={css.deliveryOption}>
          <div className={css.optionIconLeft}>
            <FontAwesomeIcon icon={faMapMarkerAlt} />
          </div>
          <div className={css.optionDetailsRight}>
            <p className={css.optionTextLink}>
              Warehouse Pickup
              <button
                onClick={() => {
                  setContentToDisplay(IMorShippingType.PICKUP)
                  setShowDeliveryDetailsModal(true)
                }}
              >
                Learn More
              </button>
            </p>
          </div>
        </div>
        <div className={css.deliveryOption}>
          <div className={css.optionIconLeft}>
            <MessagesIcon />
          </div>
          <div className={css.optionDetailsRight}>
            <p className={css.optionTextLink}>
              Product Questions?
              <button onClick={() => (PodiumWebChat ? PodiumWebChat.open() : null)}>Chat with Us</button>
            </p>
          </div>
        </div>
      </div>
      {showDeliveryDetailsModal && (
        <GenericMessageModal customClass={css.deliveryDetailsModal} onClose={() => setShowDeliveryDetailsModal(false)}>
          {contentToDisplay === IMorShippingType.SHIPPING ? renderShippingContent() : renderPickupContent()}
        </GenericMessageModal>
      )}
    </div>
  )
}
